jQuery(document).ready(function ($) {

  // Slides
  var swiper = new Swiper('.js-slide-highlights', {
    slidesPerView: 3,
    spaceBetween: 40,
    watchOverflow: true,
    navigation: {
      nextEl: ".js-slide-highlights .swiper-button-next",
      prevEl: ".js-slide-highlights .swiper-button-prev",
    },
    breakpoints: {
      1023: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 1,
      }
    }
  });

  var swiper = new Swiper('.js-slide-characteristics', {
    slidesPerView: 3,
    spaceBetween: 40,
    watchOverflow: true,
    navigation: {
      nextEl: ".js-slide-characteristics .swiper-button-next",
      prevEl: ".js-slide-characteristics .swiper-button-prev",
    },
    breakpoints: {
      1023: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 1,
      }
    }
  });

  var swiper = new Swiper('.js-slide-environments', {
    slidesPerView: 'auto',
    centeredSlides: true,
    paginationClickable: true,
    watchOverflow: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.js-slide-environments .swiper-pagination',
      type: 'bullets',
      clickable: true,
    }
  });

  var swiper = new Swiper('.js-slide-depoiments', {
    slidesPerView: 3,
    spaceBetween: 40,
    watchOverflow: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.js-slide-depoiments .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      1023: {
        slidesPerView: 2,
      },
      600: {
        slidesPerView: 1,
      }
    }
  });

  var swiper = new Swiper('.js-slide-blog', {
    slidesPerView: 'auto',
    centeredSlides: true,
    paginationClickable: true,
    watchOverflow: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: '.js-slide-blog .swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      1023: {
        slidesPerView: 2,
        spaceBetween: 20,
        centeredSlides: false
      },
      767: {
        slidesPerView: 1,
      }
    }
  });

  // Header
  $(window).on('scroll', function() {
    const $html = $('html'),
          $this = $(this);
    
    if ($this.scrollTop() != 0) {
      $html.addClass('is-active-header')
    } else {
      $html.removeClass('is-active-header')
    }
  });

  // Remove action
  $('.js-no-action').on('click', function(e) {
    e.preventDefault();
  });

  // Menu mobile
  $('.js-menu-mobile').on('click', function(e) {
    const $html = $('html');

    $html.toggleClass('is-active-menu');
  });

  // Pause video html
  $('.js-pause-video').on('click', function(e) {
    e.preventDefault();
    const video = $('.video-html'),
          $this = $(this);

    $this.toggleClass('is-paused');
    
    if ( $this.hasClass('is-paused') ) {
      $('.play').show();
      $('.pause').hide();
    } else {
      $('.play').hide();
      $('.pause').show();
    }
    
    video[0].paused ? video.trigger('play') : video.trigger('pause');
  });

  // Play video yt
  $('.js-video-player').on('click', function() {
    const $video = $(this),
          videoSrc = $video.data('video'),
          videoPlayer = $video.children('.js-video-play');
    
    $video.addClass('is-playing');
    videoPlayer.attr('src', videoSrc);
  });

  // Play video html
  $('.js-video-html').on('click', function(el) {
    el.preventDefault();
    const $this = $(this);

    $this.attr("controls","controls");
    $this.parent().toggleClass('is-playing');
    $this[0].paused ? $this.trigger('play') : $this.trigger('pause');
  });

  // Go to
  $('.js-goto').on('click', function(e) {
    e.preventDefault();
    const id = $(this).attr('href');
    $('html').animate({scrollTop: $(id).offset().top - 90}, 'slow')
  });

  // Accordion
  $('.js-accordion').on('click', function() {
      const $this = $(this);

      $this.toggleClass('is-active');
      $this.parent().find('.c-policy-accordion__text').slideToggle();
  });

  // Hover menu
  $('.js-hover-menu').append($('.js-append-submenu').html());
  $('.js-hover-menu > a').on('click', function(el) {
    el.preventDefault();
  });

  $('.js-hover').hover(function() {
    const $name = $(this).attr('name');
    $('.c-menu-submenu__block').removeClass('is-active');
    $('#' + $name).addClass('is-active');
  });

  $(function() {
  
    // Privacy Cookie Alert
    var gdprcookie = getCookie('gdprcookie');
  
    if (gdprcookie != 'yes') {
      $('.c-privacy-alert').removeClass('is-hidden');
    }
  
    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
  
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
  
    function getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(';');
  
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
  
  
    // Close cookie alert
    $('.js-accept-alert').on('click', function(e) {
      e.preventDefault();
  
      setCookie("gdprcookie", 'yes', 30);
      $(this).parents('.c-privacy-alert').addClass('is-hidden');
    });

    $('.js-close-alert').on('click', function(e) {
      e.preventDefault();
  
      $(this).parents('.c-privacy-alert').addClass('is-hidden');
    });
  
  });

  // Animeted on scroll
  $(window).on('scroll', function() {
    const winHeight = $(this).innerHeight(),
          winScroll = $(this).scrollTop() + winHeight;

    $('.js-animated').each(function() {
      const $this = $(this),
            elTop = $this.offset().top;

      if(winScroll >= elTop + winHeight / 3) {
        $this.addClass('is-visible');
      }
    });

    if(winScroll >= 20) {
      $('.js-sub-menu').removeClass('is-active-sub-menu');
    }
  });

  // Animated start
  function start() {
    $('.js-animated-start').addClass('is-visible');
  }

  start();

  // MASKED INPUT
  $(".js-data").mask("99/99/9999");
  $(".js-cpf").mask("999.999.999-99");
  $(".js-cep").mask("99999-999");
  $(".js-cnpj").mask("99.999.999/9999-99");
  $('.js-phone').focusout(function(){
    var phone, element;
    element = $(this);
    element.unmask();
    phone = element.val().replace(/\D/g, '');
    if(phone.length > 10) {
      element.mask("(99) 99999-999?9");
    } else {
      element.mask("(99) 9999-9999?9");
    }
  }).trigger('focusout');

});